<template>
  <div class="travel-plan-add">
    <van-row type="flex" justify="center" align="center" class="card">
      <van-col span="17">
        <van-field style="border-radius: 6px 0 0 0;" label="标题" placeholder="请输入标题"
                   v-model="cardInfo.title"></van-field>
        <van-cell-group>
          <van-field label="计划出发时间" placeholder="请输入计划出发时间" v-model="cardInfo.startTime"
                     @click="show = true"></van-field>
          <van-calendar v-model="show" @confirm="onConfirm" :show-confirm="false"/>
          <van-field style="border-radius: 0 0 0 6px;" placeholder="请输入计划返回时间" label="计划返回时间" @click="show1 = true"
                     v-model="cardInfo.endTime"></van-field>
          <van-calendar v-model="show1" @confirm="onConfirm1" :show-confirm="false"/>
        </van-cell-group>
      </van-col>
      <van-col span="7">
        <van-row>
          <van-icon name="envelop-o"/>
        </van-row>
        <van-row>
          <span>
            <button style="background-color: #378FF2; border:none" :disabled="flag" @click="submitYkb()">提交易快报
            </button>
          </span>
        </van-row>
      </van-col>
    </van-row>
    <div class="list" v-for="(item,index) in list" v-bind:key="index">
      <van-row type="flex" justify="start" align="center">
        <van-swipe-cell style="width: 100%; height: 100%">
          <van-col span="20">
            <van-row class="list-title">
              {{ item.customerName }}
            </van-row>
            <van-row>
              <van-col class="list-info" span="4">{{ item.createdPeople }}</van-col>
              <van-col class="list-info" span="6">{{ item.enterpriseNature }}</van-col>
              <van-col class="list-info" span="6">{{ item.enterpriseType }}</van-col>
              <van-col class="list-info" span="8">{{ item.group }}</van-col>
            </van-row>
            <van-row class="list-info">
              {{ item.addressDetailed }}
            </van-row>
          </van-col>
          <van-col span="4" @click="to(item.to)">
            <van-icon style="margin-top: 30px" name="edit"/>
          </van-col>
          <template slot="right">
            <van-button square type="danger" @click="deleteList(item.travelPlanid)" text="删除"/>
          </template>
        </van-swipe-cell>
      </van-row>
    </div>
    <!-- 添加按钮-->
    <div class="add">
      <van-button class="add_button" color="#FD843E" icon="plus" @click="toAdd()"/>
    </div>
    <crm-tab-bar :tabpage="1"/>
  </div>
</template>

<script>
import CrmTabBar from "@/components/CrmTabBar";
import Add from '@/components/Add';
import {Toast} from "vant";

export default {
  name: "BulkCrmsTravelPlanAdd",
  components: {CrmTabBar, Add},
  data() {
    return {
      flag: false,
      date: '',
      date1: '',
      show: false,
      show1: false,
      cardInfo: {
        startTime: '',
        endTime: '',
        title: ''
      },
      list: []
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$ajax.post('/api/ajax/planVisit/getBulkCrmPlanvisitList.json',
          {
            titleId: this.$route.params.name,
            createdPeople: this.$store.getters.userId,
            travelPlanid: ''
          }).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          if (res.data.length > 0) {
            this.list = res.data
            this.cardInfo.title = res.data[0].titleName
            this.cardInfo.startTime = res.data[0].plandateStart
            this.cardInfo.endTime = res.data[0].plandateEnd
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].to = '/BulkCrmsTravelPlanRecord/' + this.list[i].travelPlanid
            }
          }
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('初始化失败！');
      })
    },
    to(to) {
      console.log(to)
      this.$router.push(to);
    },
    onConfirm(date) {
      this.show = false;
      this.cardInfo.startTime = this.formatDate(date);
    },
    onConfirm1(date) {
      this.show1 = false;
      this.cardInfo.endTime = this.formatDate(date);
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    toAdd() {
      if (this.cardInfo.title == '' || this.cardInfo.startTime == '' || this.cardInfo.endTime == '') {
        this.$message.info('请补全标题信息！');
      } else {
        if (this.cardInfo.title.includes("/")) {
          this.$message.warning('标题不允许包含/字符！');
        } else {
          this.$router.push({path: "/BulkCrmsTravelPlanRecordAdd/" + this.cardInfo.title + "/" + this.$route.params.name + "/" + this.cardInfo.startTime + "/" + this.cardInfo.endTime + "/add"});
        }
      }
    },
    submitYkb() {
      this.flag = true
      if (this.list.length > 0) {
        if (this.list[0].ykbStatus == '00') {
          var customer = ''
          for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].interviewType.length == 0 || this.list[i].goalPlan.length == 0 || this.list[i].length == 0) {
              customer = this.list[i].customerName
              break;
            }
          }

          if (customer.length == 0) {

            console.log(1)
            this.$ajax.post('/api/ajax/planVisit/updateBulkCrmPlanvisitStatus.json',
                {
                  titleId: this.list[0].titleId,
                  createdPeople: this.$store.getters.userId,
                  titleName: this.cardInfo.title,
                  plandateStart: this.cardInfo.startTime,
                  plandateEnd: this.cardInfo.endTime
                }).then(res => {
              if (res.status == 200) {
                if (res.data == "-1") {
                  Toast.fail('提交失败');
                  this.$message.error('易快报未生成，请联系运管中心！');
                  this.flag = false
                } else {
                  Toast.success('提交成功');
                  this.$message.success('提交成功！');
                  //重新初始化查询
                  setTimeout(() => {
                    this.$router.push({path: '/BulkCrmsTravelPlanList'})
                  }, 200);
                }
              }
            }).catch(err => {
              Toast.fail('提交失败');
              console.log(err)
              this.$message.error('提交失败！');
            })
          }
          else{
            this.$message.info('请补全' + customer + '！');
          }
        }

        else {
          this.$message.info('不允许重复提交易快报！');
        }
      } else {
        this.$message.info('没有客户，不允许提交易快报！');
      }
    },
    deleteList(item) {
      if (this.list[0].ykbStatus == '00') {
        this.$ajax.post('/api/ajax/planVisit/deleteBulkCrmPlanvisit.json',
            {
              travelPlanid: item
            }).then(res => {
          if (res.status == 200) {
            this.$message.success('删除成功！');
            //重新初始化查询
            setTimeout(() => {
              this.init()
            }, 200);
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('删除失败！');
        })
      } else {
        this.$message.warning('当前状态不允许删除！');
      }
    }
  }
}
</script>

<style scoped>
.travel-plan-add {
  background: #f3f3f3;
}

.travel-plan-add .card {
  margin: 10px 5px;
  background: #378FF2;
  border-radius: 6px;
  box-shadow: 6px 6px 5px #888888;
}

.travel-plan-add .list {
  background: #FFFFFF;
  padding: 5px 10px;
  margin-top: 5px;
}

.travel-plan-add .list-title {
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: left;
  font-weight: 400;
  font-size: 16px;
  color: #000000;
  font-style: normal;
  letter-spacing: 0;
  line-height: 24px;
  text-decoration: none;
}

.travel-plan-add .list-info {
  padding-top: 5px;
  text-align: left;
  font-weight: 400;
  font-size: 14px;
  color: #827E7E;
  font-style: normal;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: none;
}

.travel-plan-add .add .add_button {
  position: fixed;
  top: 75%;
  left: 80%;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #F98433;
}

.travel-plan-add .form-onlyRead {
  pointer-events: none
}
</style>